import {
  type ClientLoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';

import { CollectiveProviders } from '../components/Collective/Providers';
import {} from '../components/Collective/utils';
import { WelcomePage } from '../components/Collective/Welcome';
import { apiService } from '../services/api-service';
import { tokenWithRedirect } from '../utils/router';

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const id = action.params.id;
  if (!id) {
    throw new Error('expected inquiry id');
  }

  const resp = await tokenWithRedirect(
    () => apiService.collective.getInquiry(id),
    action.request.url
  );

  return { data: resp.data };
};

function InquiryInterview() {
  const {
    data: { inquiry },
  } = useLoaderData<typeof clientLoader>();
  const navigate = useNavigate();

  const handleStart = async () => {
    const resp = await apiService.collective.createInterview({
      inquiryId: inquiry.id,
    });

    const interview = resp.data.interview;
    navigate(`/interviews/${interview.id}?welcome=true`);
  };

  return (
    <CollectiveProviders>
      <WelcomePage inquiry={inquiry} onStart={handleStart} />
    </CollectiveProviders>
  );
}

export const Component = InquiryInterview;
